import React from 'react'
import Layout from '../components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import GoogleMapReact from 'google-map-react';

const marker = { lat: 47.047916, lng: 8.304718 };
const center = { lat: 47.049336, lng: 8.304718 };

class Kontakt extends React.Component {

  state = {
    showInfo: false
  }

  showMarkerInfo = () => {
    this.setState({
      showInfo: true
    })
  }

  hideMarkerInfo = () => {
    this.setState({
      showInfo: false
    })
  }

  render() {

    return (
      <Layout>
        <section id="contact" className="section-wrapper">
          <header className="section-header">
            <h3>Kontakt</h3>
          </header>
          <div className="row contact-info">
            <div className="col-md-4">
              <div className="contact-address">
                <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" color="#9acf47" />
                <h3>Adresse</h3>
                <p>LEHMANN+PARTNER Informatik AG, Pilatusstrasse 39, CH-6003 Luzern</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-phone">
                <FontAwesomeIcon icon={faPhone} size="3x" color="#9acf47" />
                <h3>Telefon</h3>
                <p><a href="tel:+41412273070">+41 41 227 3070</a></p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-email">
                <FontAwesomeIcon icon={faEnvelope} size="3x" color="#9acf47" />
                <h3>Email</h3>
                <p><a href="mailto:info@lepa.ch">info@lepa.ch</a></p>
              </div>
            </div>
          </div>
          <div style={{ width: '100%', height: '500px' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyCuTxFVrMigCg_R-BPCVbNG0Jq2NvdDDLY' }}
              defaultCenter={center}
              defaultZoom={16}
              onClick={this.hideMarkerInfo}
            >
              <div lat={marker.lat} lng={marker.lng} className="map-marker" onClick={this.showMarkerInfo}>
                <div className={this.state.showInfo ? 'map-marker-info shown' : 'map-marker-info'}>
                  <b>LEHMANN+PARTNER Informatik AG</b>
                  <br />
                  Pilatusstrasse 39
                  <br />
                  6003 Luzern
                  <br />
                  Schweiz
                </div>
              </div>
            </GoogleMapReact>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Kontakt;
